<template>
  <CModal
    :show.sync="isShowPopup"
    :close-on-backdrop="false"
    :color="modalColor"
  >
    <template #header>
      <h2>Welcome to {{ item.orgName }}</h2>
      <CButtonClose @click="modalCallBack(false)" class="text-black" />
    </template>
    <template #footer-wrapper>
      <div class="border-top d-flex py-3 mx-3">
        <div class="flex-grow-1 d-flex"></div>
        <div class="d-flex">
          <CButton
            type="button"
            color="primary"
            class="px-4"
            @click="closeModal()"
            >{{ buttonLabel }}</CButton
          >
        </div>
      </div>
    </template>
    <div>
      <p>
        <b class="primary">{{ item.campaignName }}</b>. Your details
        have been successfully registered. Please check your profile remains
        up-to-date, and we wish you every success!
      </p>
    </div>
  </CModal>
</template>

<script>
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: {},
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    modalColor: {
      type: String,
      default: "primary",
    },
    buttonLabel: {
      type: String,
      default: "Ok",
    },
  },
  methods: {
    closeModal() {
      this.$emit("modalCallBack", false);
    },
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
  },
};
</script>
